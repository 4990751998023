import dayjs from "dayjs";
import { createStore } from "@/vuex/store";
import { getBalance } from "@/api/api";
import { ElMessage } from "element-plus";
import { appId } from "@/utils/url";

import router from "../router";

const store = createStore();
function addChatData(item: any) {
  if (!localStorage.getItem("token")) return;
  store.action.updateUserList(item);
  // store.action.updatePrompt(item.introduction);
  if (item.type !== 'WORKFLOW') {
    const data = {
      person: 1,
      text: item.introduction,
      date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };
    const isFirst = true;
    store.action.updateChatData(item.id, data, isFirst);
  }
  store.action.repeatNowSelect(item);
}
function addChatDataforWeb(item: any) {
  store.action.updateUserList(item);
  // store.action.updatePrompt(item.introduction);
  const data = {
    person: 1,
    text: item.introduction,
    date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
  };
  const isFirst = true;
  store.action.updateChatData(item.id, data, isFirst);
  store.action.repeatNowSelect(item);
}

function updataBalance() {
  getBalance().then((res: any) => {
    if (res.code === 200) {
      store.action.updataBalance(res.data);
    }
  });
}

let isLoading = true;
function isToken() {
  if (localStorage.getItem("token")) {
    return true;
  } else {
    if (isLoading) {
      isLoading = false;
      const localHerf = window.location.href;
      const isPortal = localHerf.includes('portal');
      if (!isPortal) {
        ElMessage({
          type: "warning",
          message: "请先登录",
        });
      }
      const tiemet = setTimeout(() => {
        isLoading = true;
        clearInterval(tiemet);
      }, 1000);
    }
    return false;
  }
}
function weXinlogin() {
  const appid = appId;
  const redirect_uri = encodeURIComponent(window.location.href); // 跳转本页，注意访问url必须是 http://wwww.xxx.com
  const scope = "snsapi_userinfo";
  const state = "1";
  const url =
    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
    appid +
    "&redirect_uri=" +
    redirect_uri +
    "&response_type=code&scope=" +
    scope +
    "&state=" +
    state +
    "#wechat_redirect";
  window.location.href = url; // 这样浏览器回退时由于不带cookie，会重复授权
}
// function weXinlogin() {
//   WapDoAuth()
// }

// 匹配code
interface UrlParams {
  code: string;
  // 在这里定义其他参数的类型
}
function extractUrlParams(url: string): UrlParams | undefined {
  const regex = /\?code=([^&]*)/;
  const match = regex.exec(url);
  const code = match && decodeURIComponent(match[1].replace(/\+/g, " "));
  if (code) {
    return { code };
  }
}
// 匹配图片
function getImageUrl(str: string) {
  // 匹配代码块
  const codeBlockRegex = /```[\s\S]*?```/g;
  const strippedMarkdown = str.replace(codeBlockRegex, ''); // 移出代码块中的内容
  const regex = /(http|https):\/\/\S+\/\S+\.(png|jpg|webp|jpeg|gif)/g; // 匹配图片
  // 得到图片数组
  const matches = strippedMarkdown.match(regex);
  return matches
}
// 权限判断
function rightJudgment(str: string) {
  // 如果为企业版 则去判断其他都判断为true
  if (store.state.user.memberGrade === 'ENTERPRISE' && store.state.user.authorizedRoute) {
    const permeerlist = store.state.user.authorizedRouteList
    return permeerlist.includes(str)
  } else {
    return true;
  }
}

function getMp4Url(str: string) {
  const regex = /(http|https):\/\/\S+\/\S+\.(mp4)/g;
  const matches = str.match(regex);
  return matches
}

// 压缩图片
const fileToDataURL = (file: Blob): Promise<any> => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onloadend = (e) => resolve((e.target as FileReader).result)
    reader.readAsDataURL(file)
  })
}
const dataURLToImage = (dataURL: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.src = dataURL
  })
}
const canvastoFile = (canvas: HTMLCanvasElement, type: string, quality: number): Promise<Blob | null> => {
  return new Promise((resolve) => canvas.toBlob((blob) => resolve(blob), type, quality))
}
/**
 * 图片压缩方法
 * @param {Object}  file 图片文件
 * @param {String} type 想压缩成的文件类型
 * @param {Nubmber} quality 压缩质量参数
 * @returns 压缩后的新图片
 */
const compressionFile = async (file: any, type = 'image/jpeg', quality = 0.5) => {
  const fileName = file.name
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d') as CanvasRenderingContext2D
  const base64 = await fileToDataURL(file)
  const img = await dataURLToImage(base64)
  canvas.width = img.width
  canvas.height = img.height
  context.fillStyle = '#fff'
  context.fillRect(0, 0, canvas.width, canvas.height);
  // context.clearRect(0, 0, img.width, img.height)
  context.drawImage(img, 0, 0, img.width, img.height)
  const blob = (await canvastoFile(canvas, type, quality)) as Blob // quality:0.5可根据实际情况计算
  const newFile = await new File([blob], fileName, {
    type: type
  })
  return newFile
}

function downloadFile(url: string): void {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', '')
  link.click();
}

function truncateString(str: string) {
  if (str) {
    const maxLength = 2000;
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + '...';
    }
    return str;
  } else {
    return str
  }

}
function getRouterName(routeList: String[]) {
  const routes = router.getRoutes()

  const matchedNames = routes
    .filter(route => routeList.includes(route.path)) // 过滤出匹配的路由
    .map(route => route.name); // 提取对应的 name
  return matchedNames
}
// 检测图片地址是否可用
function checkImageExists(imageUrl: any, callback: any) {
  var img = new Image();
  img.onload = function () {
    callback(true);
  };
  img.onerror = function () {
    callback(false);
  };
  img.src = imageUrl;
}

function authIsShow(code: string) {
  if (localStorage.getItem('user')) {
    // store
    // const userDetail =
    // if (userDetail.authorizedRoute && ) {

    // }
    if (store.state.user.memberGrade === 'ENTERPRISE' && store.state.user.authorizedRoute) {
      try {
        const permeerlist = store.state.user.authorizedRouteList
        if (permeerlist.includes(code)) {
          return true
        } else {
          return false
        }
      } catch {
        return true
      }
    } else {
      return true
    }
  }

}

function parseMarkdownWithLaTeXHandling(text: string): string {
  let result: string = '';
  let inCodeBlock: boolean = false;
  // 分割行来进行逐行解析
  const lines: string[] = text.split('\n');
  lines.forEach((line: string) => {
    if (line.trim().startsWith('```')) {
      inCodeBlock = !inCodeBlock;
      result += line + '\n';
      return;
    }
    if (inCodeBlock) {
      // 在代码块中，直接添加行
      result += line + '\n';
    } else {
      // 不在代码块中，处理 LaTeX
      let processedLine: string = line
        .replace(/\\\[/g, '$$')
        .replace(/\\\]/g, '$$')
        .replace(/\\\(/g, '$')
        .replace(/\\\)/g, '$')
        .replace(/\$(.*?)\$/g, (match, p1) => `$${p1.trim()}$`)
        .replace(/\$\$(.*?)\$\$/g, (match, p1) => `$${p1.trim()}$$`);
      result += processedLine + '\n';
    }
  });
  return result;
}
function deepClone<T>(obj: T, hash: WeakMap<object, any> = new WeakMap()): T {
  // 处理 null 或者不是对象的情况
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  // 将 obj 转换为 unknown，然后再转换为 object
  const objAsObject = obj as unknown as object;

  // 如果对象已经被克隆过，直接返回克隆结果，解决循环引用的问题
  if (hash.has(objAsObject)) {
    return hash.get(objAsObject);
  }

  // 处理日期对象
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as unknown as T;
  }

  // 处理正则表达式对象
  if (obj instanceof RegExp) {
    return new RegExp(obj.source, obj.flags) as unknown as T;
  }

  // 创建一个新对象/数组作为克隆的基础
  const clonedObj: any = Array.isArray(obj) ? [] : {};

  // 将新对象存储到哈希表中，以处理循环引用
  hash.set(objAsObject, clonedObj);

  // 遍历对象的属性并进行递归克隆
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = deepClone((obj as any)[key], hash);
    }
  }
  return clonedObj as T;
}
export { addChatData, addChatDataforWeb, updataBalance, isToken, weXinlogin, extractUrlParams, getImageUrl, compressionFile, downloadFile, getMp4Url, truncateString, checkImageExists, getRouterName, authIsShow, rightJudgment, parseMarkdownWithLaTeXHandling, deepClone };
